<template>
<div 
  :class="`h-${iconSize} w-${iconSize} card self-center`"
>
  <svg 
  :class="`h-${iconSize} w-${iconSize}`" viewBox="0 0 25 24"
 fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.7413 14.3777C16.7413 12.0005 15.315 11.1855 12.4624 10.846C10.4248 10.5742 10.0173 10.0309 10.0173 9.07994C10.0173 8.12896 10.6965 7.51785 12.0548 7.51785C13.2774 7.51785 13.9566 7.92536 14.2962 8.94416C14.3642 9.14791 14.5679 9.2837 14.7717 9.2837H15.8583C16.13 9.2837 16.3338 9.07994 16.3338 8.80837V8.7404C16.062 7.24612 14.8395 6.09154 13.2774 5.95576V4.32569C13.2774 4.05396 13.0736 3.8502 12.7341 3.78223H11.7153C11.4436 3.78223 11.2398 3.98598 11.1718 4.32569V5.88778C9.13426 6.15951 7.84389 7.51785 7.84389 9.21589C7.84389 11.4572 9.20223 12.3401 12.0548 12.6798C13.9566 13.0193 14.5679 13.4268 14.5679 14.5136C14.5679 15.6004 13.6169 16.3474 12.3266 16.3474C10.5606 16.3474 9.94929 15.6002 9.74553 14.5814C9.67772 14.3098 9.47396 14.1739 9.2702 14.1739H8.11546C7.8439 14.1739 7.64014 14.3777 7.64014 14.6494V14.7174C7.91171 16.4152 8.99847 17.6378 11.2398 17.9775V19.6076C11.2398 19.8791 11.4436 20.0829 11.7831 20.1509H12.8019C13.0736 20.1509 13.2774 19.9471 13.3454 19.6076V17.9775C15.3829 17.6378 16.7413 16.2115 16.7413 14.3777Z" :fill="iconColor"/>
    <path d="M8.79476 21.5087C3.49705 19.607 0.780221 13.698 2.74999 8.46814C3.76878 5.61552 6.01012 3.44216 8.79476 2.42337C9.0665 2.28758 9.20228 2.08382 9.20228 1.74412V0.793301C9.20228 0.521569 9.0665 0.317811 8.79476 0.25C8.72679 0.25 8.59101 0.25 8.52303 0.31781C2.07074 2.35539 -1.46112 9.21536 0.576462 15.6676C1.79901 19.4711 4.7196 22.3917 8.52303 23.6142C8.79476 23.75 9.0665 23.6142 9.13431 23.3425C9.20228 23.2747 9.20228 23.2067 9.20228 23.0709V22.1199C9.20228 21.9162 8.99852 21.6446 8.79476 21.5087ZM15.9943 0.31781C15.7225 0.182026 15.4508 0.31781 15.383 0.589542C15.315 0.657516 15.315 0.725327 15.315 0.861275V1.81209C15.315 2.08382 15.5188 2.35539 15.7225 2.49134C21.0203 4.39297 23.7371 10.302 21.7673 15.5319C20.7485 18.3845 18.5072 20.5578 15.7225 21.5766C15.4508 21.7124 15.315 21.9162 15.315 22.2559V23.2067C15.315 23.4784 15.4508 23.6822 15.7225 23.75C15.7905 23.75 15.9263 23.75 15.9943 23.6822C22.4466 21.6446 25.9784 14.7846 23.9408 8.33235C22.7183 4.46095 19.7297 1.54036 15.9943 0.31781Z" :fill="iconColor"/>
  </svg>
</div>
</template>

<script>
import { ref, toRefs } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'
export default {
  props: ['color', 'size'],
  setup(props) {
    const { color, size } = toRefs(props)

    const iconColor = ref("#A0A4B1")
    const iconSize = ref("5")

    onMounted(() => {
      iconColor.value = color.value || "#A0A4B1"
      iconSize.value = size.value || "5"
    })

    watch(color, (val) => {
      iconColor.val = val
    })
    watch(size, (val) => {
      iconSize.val = val
    })

    return {
      iconColor,
      iconSize
    }
  },
}
</script>